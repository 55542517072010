import React from "react"
import { graphql } from 'gatsby'
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageCommercialSection from "../sections/PageCommercialSection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"

export default ({ data }) => (
  <PageContainer>
    <HeadData 
      title='Commercial Services' 
      description='Interpacific Asset Management currently manages a wide variety of commercial properties, from retail centers, to industrial parks, to office buildings and mobile home parks.'
    />

    <PageHeaderImageBg imageData={data.imageBg.childImageSharp.fluid}>
      <HeaderNav />
      <PageHeaderText
        title='Commercial Services'
      />
    </PageHeaderImageBg>

    <PageCommercialSection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)

export const query = graphql`
  query CommercialServicesPageQuery {
    imageBg: file(relativePath: { eq: "services/home-service-commercial-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

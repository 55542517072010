import React from 'react' 
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 80px;
  margin-bottom: 22px;
`

const PageCommercialSection = () => (  
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid
        const imageDataTwo = data.imageTwo.childImageSharp.fluid

        return (
          <div className='bg-gray-200'>
            <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
        
              <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management currently manages a wide variety of commercial properties, from retail centers, to industrial parks, to office buildings and mobile home parks. We strongly believe in providing only the most efficient and professional service possible to each and every owner and their tenants.</h2>

              <div className='lg:flex block'>
                <p className='lg:w-1/2 w-full text-xl text-gray-900 mb-6 pr-8 sm:pr-0'>Interpacific is a member of the Institute of Real Estate Management (IREM), which is a division of the National Association of Realtors, through which our property managers gained their designation “Certified Property Manager” (CPM). Interpacific is very proud to be an Accredited Management Organization (AMO), which means that it has met the highest standards of integrity, experience, education, accounting principles and insurance requirements set forth by IREM. Worldwide, there are only about 700 firms that have met these requirements. This makes selecting an AMO in your best interest for assurance that professionals with integrity and experience will manage your property.</p>

                <div className='flex flex-col items-center w-1/2 sm:w-full p-6 sm:p-0'>
                  <Img fluid={imageDataOne} alt='' className='w-full mb-12' />
                  <Img fluid={imageDataTwo} alt='' className='w-full' />
                </div>
              </div>
        
              <StyledUnderline></StyledUnderline>
        
              <h3 className='font-display text-gray-900 text-2xl sm:text-xl leading-snug mb-8'>Our Management Program Includes:</h3>
        
              <ul className='flex flex-wrap justify-between p-4'>
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Goal Establishment.</span> We will meet with owners to fully understand and implement short-term and long-range goals for the property.
                  </div>
                </li>
        
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Property Inspections.</span> Our property managers will conduct regular and thorough inspections to ensure that your property is in top condition.
                  </div>
                </li>
        
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Repairs and Maintenance.</span> We provide quick and effective resolution to service requests and have trained personnel to respond to and follow up on all requests. We also provide a summary of all work orders in the monthly reports.
                  </div>
                </li>
        
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Fiscal and Accounting Services.</span> Interpacific will provide monthly financial statements tailored to each owner’s needs, and prepare a yearly budget. We utilize effective collection practices which maximize collection of rents, common area maintenance impounds, and escalations.
                  </div>
                </li>
        
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Leasing and Tenant Relations.</span> We will negotiate new leases, renew existing leases, and provide lease administration ensuring that escalations, common area maintenance charges, pass-throughs, and other lease provisions are collected and enforced. We will institute procedures against tenants who fail to meet obligations of the lease contract, and will respond to tenant inquiries and maintenance requests. A happy tenant is a loyal tenant!
                  </div>
                </li>
        
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Environmental Management.</span> If reducing energy expenses in today’s uncertain energy market appeals to you, we can help. We are familiar with the installation and operation of all types of energy management systems for a variety of applications. We are also familiar with asbestos, toxic mold remediation, water quality and other important environmental issues.
                  </div>
                </li>
        
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Market Surveys.</span> We can provide market surveys to ensure you are getting the most competitive rate for your property.
                  </div>
                </li>
        
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Disposition.</span> We will assist in the marketing and sale of your property.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )
      }
    }
  />      
)

export default PageCommercialSection

const query = graphql`
  query PageCommercialSectionQuery {
    imageOne: file(relativePath: { eq: "logos/irem.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "logos/amo-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`